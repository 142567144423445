import { FeatureKey } from '@core/feature-preview/types/feature-key';
import {
  FeaturePreview,
  FeaturePreviewStatus,
} from '@core/feature-preview/types/feature-preview';

export const Features: { [k in FeatureKey]: FeaturePreview } | undefined = {
  Dashboard: {
    key: 'Dashboard',
    name: 'Dashboard',
    description: 'Will enable dashboard feature',
    status: FeaturePreviewStatus.PREVIEW,
  },
  Workflow: {
    key: 'Workflow',
    name: 'Workflow',
    description:
      'Will replace process area with a new workflow area to group process definitions',
    status: FeaturePreviewStatus.PREVIEW,
  },
  Copy_Paste_Shortcuts: {
    key: 'Copy_Paste_Shortcuts',
    name: 'Copy_Paste_Shortcuts',
    description:
      'Enables Shortcuts: copy: (win-alt/mac-option) + shift + c and paste: (win-alt/mac-option) + shift + v in to copy and paste Activities in the builder',
    status: FeaturePreviewStatus.PREVIEW,
  },
  DetectionDefinitions: {
    key: 'DetectionDefinitions',
    name: 'Detection Definitions',
    description: 'Enables trigger sidebar item',
    status: FeaturePreviewStatus.HIDDEN,
  },
};
